export const ROLES = {
  ADMIN: 'admin',
  SALES_MANAGER: 'sales_manager',
  IQIP_PROJECT_MANAGER: 'iqip_project_manager',
  PROJECT_ENGINEER: 'project_engineer',
  GEOTECHNICAL_ADVISOR: 'geotechnical_advisor',
  IQIP_FLEET_MANAGER: 'global_fleet_manager',
  CUSTOMER_SERVICE: 'customer_service',
  SERVICE_ENGINEER: 'service_engineer',

  COMPANY_ADMIN: 'company_admin',
  COMPANY_FLEET_MANAGER: 'company_fleet_manager',
  COMPANY_PROJECT_MANAGER: 'company_project_manager',
  OPERATOR_A: 'operator_a',
  OPERATOR_B: 'operator_b',
  OPERATOR_C: 'operator_c',
};
