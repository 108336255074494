export const DATETIME_FORMATS = {
  TIMESTAMP: 'X',
  FULL_DAY_WITH_YEAR: 'dddd DD MMMM YYYY',
  FULL_DAY_WITH_YEAR_AND_TIME: 'dddd DD MMMM YYYY, HH:mm',
  FULL_DAY: 'dddd DD MMMM',
  SHORT_DAY: 'dddd DD/MM',
  RAW_DATE: 'YYYY-MM-DD',
  DATETIME: 'DD MMMM HH:mm',
  RAW_DATETIME_WITH_YEAR_NO_TIME: 'MMMM Do YYYY',
  DATETIME_WITH_YEAR: 'MMMM Do YYYY, HH:mm',
  DATETIME_WITH_YEAR_AND_TIME: 'DD MMMM YYYY, HH:mm',
  DATETIME_WITH_YEAR_AND_SECONDS: 'DD MMMM YYYY, HH:mm:ss',
  RAW_DATETIME_WITH_YEAR: 'YYYY-MM-DD, HH:mm:ss',
  RAW_DATETIME_WITH_YEAR_NO_COMMA: 'YYYY-MM-DD HH:mm:ss',
  RAW_DATETIME_WITH_YEAR_REVERSE: 'HH:mm:ss YYYY-MM-DD',
  RAW_DATETIME_WITH_YEAR_UTC: 'HH:mm:ss DD-MM-YYYY',
  FULL_DATE: 'DD MMMM YYYY',
  LOCAL_DATE: 'LL',
  DATE: 'DD-MM-YYYY',
  DATE_FLAT: 'DD/MM/YYYY',
  DATE_UNICODE: 'dd-MM-yyyy',
  MONTH_YEAR: 'MMMM-YYYY',
  DAY: 'DD',
  MONTH: 'MMMM',
  YEAR: 'YYYY',
  WEEKDAY: 'dddd',
  TIME: 'HH:mm',
  TIME_UTC: 'HH:mm UTC',
};
