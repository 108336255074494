export const KEYS = {
  ACCESS_TOKEN: 'access_token',
  ACCEPT_TERMS: 'accept_terms',
  ACCOUNT: 'account',
  ACTIVE: 'Active',
  ACTIVE_COMPANY_ID: 'active_company_id',
  ASCENDING: 'asc',
  ALERT: 'alert',
  ALERTS: 'alerts',
  ALERTS_SELECTED: 'selectedAlert',
  ACCOUNT_SWITCH: 'account_switch',
  BACKGROUND_COVER: 'bgc',
  BAR: 'bar',
  BODY: 'body',
  BREADCRUMBS: 'breadcrumbs',
  BYPASSED: 'bypassed',
  CAP: 'cap',
  CHILDREN: 'children',
  CLOSEABLE: 'closeable',
  COLLECTION: 'collection',
  COMPANIES: 'companies',
  COMPANY: 'company',
  CONFIGURATION: 'configuration',
  CONFIGURATIONS: 'configurations',
  CONFIRM: 'confirm',
  CONFIRM_PASSWORD: 'confirm-password',
  CONTACT_CLIENT: 'client_contacted',
  CONTEXTUAL_MENU: 'contextual_menu',
  CONTRACT: 'contract',
  CONTRACTS: 'contracts',
  CONTRACT_CONFIGURATIONS: 'contract_configurations',
  TRANSFERS: 'transfers',
  CONTROL_UNIT: 'control_unit',
  CONTROL_UNITS: 'control_units',
  CONTROL_UNIT_TYPE: 'control_unit_type',
  CONTROL_UNIT_TYPES: 'control_unit_types',
  CONTROL_UNIT_TYPE_SOFTWARE: 'control_unit_type_software',
  CONTROL_UNIT_REPORTS: 'control_unit_reports',
  COUNTS: 'counts',
  CURRENT_MONTH: 'current_month',
  CURRENT_NAVIGATION: 'current_navigation',
  CURRENT_QUARTER: 'current_quarter',
  CURRENT_WEEK: 'current_week',
  DASHBOARD_PROJECTS: 'dashboard_projects',
  DEB: 'deb',
  DEFAULT: 'default',
  DELETED_AT: 'deleted_at',
  DESCENDING: 'desc',
  DESCRIPTIONS: 'descriptions',
  DESCRIPTION: 'description',
  DRAWER: 'drawer',
  DURATION: 'duration',
  EMAIL: 'email',
  EQUIPMENT: 'equipment',
  EQUIPMENT_TYPE: 'equipment_type',
  EQUIPMENT_TYPES: 'equipment_types',
  ERROR: 'error',
  EXPANDED: 'expanded',
  EXPIRES_AT: 'expires_at',
  EXPIRES_IN: 'expires_in',
  LAST_ACTIVITY: 'last_activity',
  FLOW: 'flow',
  HAMMER: 'hammer',
  HAMMERS: 'hammers',
  HAMMER_TYPE: 'hammer_type',
  HAMMER_TYPES: 'hammer_types',
  ID: 'id',
  IHC_IQIP_RENTAL: 'IHC IQIP',
  IMPERSONATED: 'impersonated',
  IMPERSONATED_ACCESS_TOKEN: 'impersonated_access_token',
  IMPERSONATED_REFRESH_TOKEN: 'impersonated_refresh_token',
  ITEM: 'item',
  ITEMS: 'items',
  JEANLUTZ_CONTROL_UNITS: 'jean_lutz_control_units',
  JEANLUTZ_DEVICES: 'jean_lutz_devices',
  JEANLUTZ_HAMMERS: 'jean_lutz_hammers',
  JEANLUTZ_POWERPACKS: 'jean_lutz_powerpacks',
  JOULE: 'joule',
  KNOWN_PROBLEM: 'known_problem',
  LAST_7_DAYS: 'last_7_days',
  LAST_15_DAYS: 'last_15_days',
  LAST_30_DAYS: 'last_30_days',
  LAST_90_DAYS: 'last_90_days',
  LIST: 'list',
  LIVEVIEW: 'liveview',
  LOADED: 'loaded',
  LOCALE: 'locale',
  MAP: 'map',
  MESSAGE_CENTER: 'message_center',
  MINMAX: 'minmax',
  MODAL: 'modal',
  MULTI_SELECT: 'multi_select',
  MY_COMPANY: 'my_company',
  NAME: 'name',
  NAVIGATION: 'navigation',
  NEW_WINDOW: 'new_window',
  NEXT: 'next',
  NOTIFICATIONS: 'notifications',
  OBJECT: 'object',
  OBJECT_NO: 'object_no',
  OPEN_ALERTS: 'open_alerts',
  RESOLVED_ALERTS: 'resolved_alerts',
  OPTIONS: 'options',
  ORDER_LIST: 'orderList',
  OTHER: 'other',
  ONLY_CUSTOMERS: 'onlyCustomers',
  ONLY_IQIP: 'onlyIqip',
  PASSWORD: 'password',
  PAST: 'Past',
  PLANNED: 'Planned',
  PILE: 'pile',
  PILES: 'piles',
  PILELISTS: 'pilelists',
  PILELIST: 'pilelist',
  PILING_REPORTS: 'piling_reports',
  POWERPACK: 'powerpack',
  POWERPACKS: 'powerpacks',
  POWERPACK_TYPE: 'powerpack_type',
  POWERPACK_TYPES: 'powerpack_types',
  POWER_PACK: 'power_pack',
  POWER_PACKS: 'power_packs',
  POCLAIN: 'poclain',
  PRESSURE_RANGE: 'pressure_range',
  PREVIOUS: 'previous',
  PREVIOUS_QUARTER: 'previous_quarter',
  PREVIOUS_MONTH: 'previous_month',
  PREVIOUS_WEEK: 'previous_week',
  PROFILE: 'profile',
  PROGRESS: 'progress',
  PROJECT: 'project',
  PROJECTS: 'projects',
  PROJECT_OPTIONS: 'project_options',
  REFRESH_TOKEN: 'refresh_token',
  RESET: 'reset',
  RESOLVED_BY_CLIENT: 'resolved_by_client',
  RESOURCE_LINKED_TO: 'resource_linked_to',
  RESTORED: 'restored',
  RENDERED: 'rendered',
  ROLE: 'role',
  ROLES: 'roles',
  ROLES_QUERY: 'roles_query',
  SCROLLER: 'ac-scroller',
  SUB_NAVIGATION: 'sub_navigation',
  SUPPORT_CENTER: 'support_center',
  SUPPORT_EMAIL_ADDRESS: 'customerservice@iqip.com',
  TABLE: 'table',
  TABLE_CONTEXTUAL_MENU: 'table_contextual_menu',
  TERMS_AND_CONDITIONS: 'terms_and_conditions',
  TIME: 'time',
  TIMING: 'timing',
  TITLE: 'title',
  TODAY: 'today',
  TRIPACTIVE: 'trip active',
  TRIPINACTIVE: 'trip inactive',
  UNKNOWN: 'unknown status',
  USER: 'user',
  USERS: 'users',
  VALVE: 'valve',
  VISIBLE: 'visible',
  WARNINGACTIVE: 'warning active',
  WARNINGINACTIVE: 'warning inactive',
  XUSR_TOKEN: 'xusr_token',
  YESTERDAY: 'yesterday',
  WIZARD: 'wizard',
};

export default KEYS;
