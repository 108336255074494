export const PERMISSIONS = {
  ALERT: {
    READ: 'ALERT.READ',
    UPDATE: 'ALERT.UPDATE',
  },
  BASIC_OPERATOR: {
    READ: 'BASIC_OPERATOR.READ',
  },
  COMPANY: {
    CREATE: 'COMPANY.CREATE',
    READ: 'COMPANY.READ',
    READ_ALL: 'COMPANY.READ_ALL',
    READ_SELF: 'COMPANY.READ_SELF',
    UPDATE: 'COMPANY.UPDATE',
    UPDATE_ALL: 'COMPANY.UPDATE_ALL',
    IMPORT: 'COMPANY.IMPORT',
    DELETE: 'COMPANY.DELETE',
  },
  CONFIGURATION: {
    READ: 'CONFIGURATION.READ',
    READ_ALL: 'CONFIGURATION.READ_ALL',
    MANAGE: 'CONFIGURATION.MANAGE',
    IMPORT_IN_PROJECT: 'CONFIGURATION.IMPORT_IN_PROJECT',
    SEND_CONFIGURATION_READ: 'CONFIGURATION.SEND_CONFIGURATION_READ',
  },
  CONTRACT: {
    READ: 'CONTRACT.READ',
    READ_ALL: 'CONTRACT.READ_ALL',
    MANAGE: 'CONTRACT.MANAGE',
  },
  CREDENTIALS: {
    READ: 'CREDENTIALS.READ',
    MANAGE: 'CREDENTIALS.MANAGE',
  },
  EQUIPMENT: {
    CREATE: 'EQUIPMENT.CREATE',
    DELETE: 'EQUIPMENT.DELETE',
    MANAGE_DETAILS: 'EQUIPMENT.MANAGE_DETAILS',
    MANAGE_SETTINGS: 'EQUIPMENT.MANAGE_SETTINGS',
    MANAGE_PARAMETERS: 'EQUIPMENT.MANAGE_PARAMETERS',
    READ: 'EQUIPMENT.READ',
    READ_ALL: 'EQUIPMENT.READ_ALL',
    READ_PARAMETERS: 'EQUIPMENT.READ_PARAMETERS',
    READ_SETTINGS: 'EQUIPMENT.READ_SETTINGS',
  },
  EQUIPMENT_TYPE: {
    CREATE: 'EQUIPMENT_TYPE.CREATE',
    DELETE: 'EQUIPMENT_TYPE.DELETE',
    MANAGE_PARAMETERS: 'EQUIPMENT_TYPE.MANAGE_PARAMETERS',
    MANAGE_SOFTWARE: 'EQUIPMENT_TYPE.MANAGE_SOFTWARE',
    DOWNLOAD_SOFTWARE: 'EQUIPMENT_TYPE.DOWNLOAD_SOFTWARE',
    READ: 'EQUIPMENT_TYPE.READ',
    READ_PARAMETERS: 'EQUIPMENT_TYPE.READ_PARAMETERS',
    UPDATE: 'EQUIPMENT_TYPE.UPDATE',
  },
  PILE: {
    CREATE: 'PILE.CREATE',
    DELETE: 'PILE.DELETE',
    EXPORT_CONTROL_UNIT: 'PILE.EXPORT_CONTROL_UNIT',
    EXPORT_XLSX: 'PILE.EXPORT_XLSX',
    IMPORT: 'PILE.IMPORT',
    READ: 'PILE.READ',
    READ_ALL: 'PILE.READ_ALL',
    READ_TRANSFERS: 'PILE.READ_TRANSFERS',
    READ_ALL_TRANSFERS: 'PILE.READ_ALL_TRANSFERS',
    SEND: 'PILE.SEND',
    UPDATE: 'PILE.UPDATE',
  },
  PROJECT: {
    CREATE: 'PROJECT.CREATE',
    CREATE_ALL: 'PROJECT.CREATE_ALL',
    DELETE: 'PROJECT.DELETE',
    IMPORT: 'PROJECT.IMPORT',
    READ: 'PROJECT.READ',
    READ_ALL: 'PROJECT.READ_ALL',
    READ_COMPANY_UID: 'PROJECT.READ_COMPANY_UID',
    READ_COMPANY_NAME: 'PROJECT.READ_COMPANY_NAME',
    UPDATE: 'PROJECT.UPDATE',
    UPDATE_COMPANY_UID: 'PROJECT.UPDATE_COMPANY_UID',
    UPDATE_COMPANY_NAME: 'PROJECT.UPDATE_COMPANY_NAME',
    UPDATE_CONFIGURATIONS: 'PROJECT.UPDATE_CONFIGURATIONS',
  },
  REPORT: {
    READ: 'REPORT.READ',
    DELETE: 'REPORT.DELETE',
    RESTORE: 'REPORT.RESTORE',
    DOWNLOAD: 'REPORT.DOWNLOAD',
    UPLOAD_FDL: 'REPORT.UPLOAD_FDL',
  },
  SOFTWARE: {
    CREATE: 'SOFTWARE.CREATE',
    DELETE: 'SOFTWARE.DELETE',
    PUSH: 'SOFTWARE.PUSH',
    READ: 'SOFTWARE.READ',
    READ_UNAVAILABLE: 'SOFTWARE.READ_UNAVAILABLE',
    UPDATE: 'SOFTWARE.UPDATE',
  },
  USER: {
    READ: 'USER.READ',
    READ_ALL: 'USER.READ_ALL',
    MANAGE: 'USER.MANAGE',
    MANAGE_ALL: 'USER.MANAGE_ALL',
    IMPERSONATE: 'USER.IMPERSONATE',
  },
  NAVIGATION: {},
};

export default PERMISSIONS;
